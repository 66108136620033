export default {
  root: {
    name: '/',
    displayName: 'navigationRoutes.home',
  },
  routes: [


    {
      name: 'detail',
      displayName: 'menu.detail',
      meta: {
        icon: 'vuestic-iconset-statistics',
      },
    },
    {
      name: 'cli',
      displayName: 'menu.cli',
      meta: {
        icon: 'vuestic-iconset-forms',
      },
    },
    {
      name: 'usage',
      displayName: 'menu.usage',
      meta: {
        icon: 'vuestic-iconset-forms',
      },
    },
    {
      name: 'backup',
      displayName: 'menu.backup',
      meta: {
        icon: 'vuestic-iconset-forms',
      },
    },
    // {
    //   name: 'security',
    //   displayName: 'menu.security',
    //   meta: {
    //     icon: 'vuestic-iconset-statistics',
    //   },
    // },

    // {
    //   name: 'restaurant',
    //   displayName: 'menu.restaurant',
    //   meta: {
    //     icon: 'vuestic-iconset-user',
    //   },
    //   disabled: true,
    //   children: [
    //     {
    //       name: 'add',
    //       displayName: 'menu.addRestaurant',
    //     },
    //     {
    //       name: 'show',
    //       displayName: 'menu.showRestaurant',
    //     },
    //   ],
    // },




  ],
}
