import { createStore } from 'vuex'

export default createStore({
  strict: true, // process.env.NODE_ENV !== 'production',
  state: {
    isSidebarMinimized: false,
    userName: 'Vasili S',
    token: '',
    isAuthenticated: false,
  },
  mutations: {
    updateSidebarCollapsedState(state, isSidebarMinimized) {
      state.isSidebarMinimized = isSidebarMinimized
    },
    setToken(state,token){
      state.token = token
    },
    setTokenOnLocalStorage(state,token){
      localStorage.setItem('token',token)
    },
    changeAuthenticatedFlag(state,flag){
      state.isAuthenticated = flag
    },
    changeUserName(state, newUserName) {
      state.userName = newUserName
      localStorage.setItem('username',newUserName)
    }
  },
  getters:{
    auth(state){
      if (localStorage.getItem('token') !== null){
        return true
      }
      return state.isAuthenticated
    },
    token(state) {
      if (localStorage.getItem('token') !== null){
        return localStorage.getItem('token')
      }
      return state.token
    },
    username(state){
      if (localStorage.getItem('username') !== null){
        return localStorage.getItem('username')
      }
      return state.userName
    }
  },
})
