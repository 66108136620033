<template>
  <svg :fill="color" width="800px" height="800px" viewBox="0 0 32 32" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;" version="1.1" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:serif="http://www.serif.com/" xmlns:xlink="http://www.w3.org/1999/xlink">

<g id="Icon">

<path d="M15,26l-0,1.998l-4,0.002c-0.552,0 -1,0.449 -1,1c0,0.552 0.449,1 1,1l10,-0.005c0.552,0 1,-0.448 1,-1c-0,-0.552 -0.449,-1 -1,-1l-4,0.002l-0,-1.997l10,0c0.796,-0 1.559,-0.316 2.121,-0.879c0.563,-0.562 0.879,-1.325 0.879,-2.121l-0,-14c0,-0.796 -0.316,-1.559 -0.879,-2.121c-0.562,-0.563 -1.325,-0.879 -2.121,-0.879l-22,0c-0.796,0 -1.559,0.316 -2.121,0.879c-0.563,0.562 -0.879,1.325 -0.879,2.121c0,3.463 0,10.537 0,14c-0,0.796 0.316,1.559 0.879,2.121c0.562,0.563 1.325,0.879 2.121,0.879l10,-0Zm-11,-4l-0,1c-0,0.265 0.105,0.52 0.293,0.707c0.187,0.188 0.442,0.293 0.707,0.293l10.903,-0c0.032,-0.003 0.064,-0.005 0.097,-0.005c0.033,0 0.065,0.002 0.097,0.005l10.903,-0c0.265,-0 0.52,-0.105 0.707,-0.293c0.188,-0.187 0.293,-0.442 0.293,-0.707l-0,-0.999l-24,-0.001Zm24,-1.999l-24,-0.001l0,-11c-0,-0.265 0.105,-0.52 0.293,-0.707c0.187,-0.188 0.442,-0.293 0.707,-0.293c0,0 22,-0 22,-0c0.265,0 0.52,0.105 0.707,0.293c0.188,0.187 0.293,0.442 0.293,0.707l-0,11.001Z"/>

  <path d="M15,11.002l-0,5.998c0,0.552 0.448,1 1,1c0.552,-0 1,-0.448 1,-1l-0,-5.998c0,-0.552 -0.448,-1 -1,-1c-0.552,-0 -1,0.448 -1,1Z"/>

  <path d="M18.998,11.994l0,4c0,0.552 0.449,1 1,1c0.552,-0 1,-0.448 1,-1l0,-4c0,-0.552 -0.448,-1 -1,-1c-0.551,-0 -1,0.448 -1,1Z"/>

  <path d="M11.003,11.994l-0,4c-0,0.552 0.448,1 1,1c0.551,-0 1,-0.448 1,-1l-0,-4c-0,-0.552 -0.449,-1 -1,-1c-0.552,-0 -1,0.448 -1,1Z"/>

  <path d="M22.997,12.994l-0,2c-0,0.552 0.448,1 1,1c0.552,-0 1,-0.448 1,-1l-0,-2c-0,-0.552 -0.448,-1 -1,-1c-0.552,-0 -1,0.448 -1,1Z"/>

  <path d="M7.004,12.994l0,2c0,0.552 0.448,1 1,1c0.552,-0 1,-0.448 1,-1l0,-2c0,-0.552 -0.448,-1 -1,-1c-0.552,-0 -1,0.448 -1,1Z"/>

</g>

</svg>
</template>

<script>
export default {
  name: 'VaIconUsage',
  props: {
    color: {
      type: String,
      default: 'inherit',
    },
  },
}
</script>

<style lang="scss">
.va-icon-menu {
  display: inline-block;
  width: 24px;
  height: 24px;
}
</style>
