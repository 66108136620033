<template>
  <va-accordion class="sidebar-accordion va-sidebar__menu__inner" v-model="accordionValue" multiply>


    <va-collapse v-for="(route, idx) in items" :key="idx">
      <template #header>
        <va-sidebar-item :active="isRouteActive(route)" :to="route.children ? undefined : { name: route.name }">

          <va-sidebar-item-content>
<!--            <va-icon :name="route.meta.icon" class="va-sidebar-item__icon"/>-->
            <va-icon-backup color="var(--va-secondary)" v-if="route.name === 'backup'" class="va-sidebar-icon"/>
            <va-icon-security color="var(--va-secondary)" v-if="route.name === 'security'" class="va-sidebar-icon"/>
            <va-icon-detail color="var(--va-secondary)" v-if="route.name === 'detail'" class="va-sidebar-icon"/>
            <va-icon-usage color="var(--va-secondary)" v-if="route.name === 'usage'" class="va-sidebar-icon"/>
            <va-icon-cli color="var(--va-secondary)" v-if="route.name === 'cli'" class="va-sidebar-icon"/>
<!--            <va-icon-backup  class="va-sidebar-icon"/>-->

            <va-sidebar-item-title>
              {{ $t(route.displayName) }}
            </va-sidebar-item-title>

<!--            <va-icon v-if="route.children" :name="accordionValue[idx] ? 'expand_less' : 'expand_more'" />-->
            <va-icon-backup color="var(--va-secondary)"  v-if="route.name === 'backup' && route.children" class="va-sidebar-icon" :name="accordionValue[idx] ? 'expand_less' : 'expand_more'"/>
            <va-icon-security color="var(--va-secondary)"  v-if="route.name === 'security' && route.children" class="va-sidebar-icon" :name="accordionValue[idx] ? 'expand_less' : 'expand_more'"/>
            <va-icon-detail color="var(--va-secondary)"  v-if="route.name === 'detail' && route.children" class="va-sidebar-icon" :name="accordionValue[idx] ? 'expand_less' : 'expand_more'"/>
            <va-icon-usage color="var(--va-secondary)"  v-if="route.name === 'usage' && route.children" class="va-sidebar-icon" :name="accordionValue[idx] ? 'expand_less' : 'expand_more'"/>
            <va-icon-cli color="var(--va-secondary)"  v-if="route.name === 'cli' && route.children" class="va-sidebar-icon" :name="accordionValue[idx] ? 'expand_less' : 'expand_more'"/>
          </va-sidebar-item-content>
        </va-sidebar-item>
      </template>
      <template v-for="(child, index) in route.children" :key="index">
        <va-sidebar-item :active="isRouteActive(child)" :to="{ name: child.name }">
          <va-sidebar-item-content>
            <div class="va-sidebar-item__icon"/>

            <va-sidebar-item-title>
              {{ $t(child.displayName) }}
            </va-sidebar-item-title>
          </va-sidebar-item-content>
        </va-sidebar-item>
      </template>
    </va-collapse>
  </va-accordion>
</template>

<script>

import VaIconVue from "@/components/icons/VaIconVue";
import VaIconBackup from '@/components/icons/VaIconBackup'
import VaIconSecurity from '@/components/icons/VaIconSecurity'
import VaIconDetail from "@/components/icons/VaIconDetail";
import VaIconUsage from "@/components/icons/VaIconUsage";
import VaIconCli from "@/components/icons/VaIconCli";
export default {
  name: "AppMenuAccordion",
  components:{
    VaIconCli,
    VaIconUsage,
    VaIconDetail,
    VaIconBackup,
    VaIconSecurity
  },
  props: {
    items: { type: Array, default: () => [] }
  },
  data () {
    return {
      accordionValue: []
    }
  },
  mounted () {
    this.accordionValue = this.items.map(i => this.isItemExpanded(i));
  },
  methods: {
    isGroup(item) {
      return !!item.children;
    },
    isRouteActive(item) {
      return item.name === this.$route.name;
    },
    isItemExpanded(item) {
      if (!item.children) {
        return false;
      }

      const isCurrentItemActive = this.isRouteActive(item);
      const isChildActive = !!item.children.find(child =>
        child.children ? this.isItemExpanded(child) : this.isRouteActive(child)
      );

      return isCurrentItemActive || isChildActive;
    }
  },
};
</script>

<style lang="scss" scoped>
.va-sidebar-icon{
  width: 23px;
  height: 23px;
}
</style>
